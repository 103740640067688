import React from 'react';
import {Suspense, lazy} from "react";
import logo from "./res/logo.png";
import firebase from "./utils/firebase";
import $ from "jquery";
import './App.css';

import Logo_blue from "./res/logo_blue.png";
import { TwitterTimelineEmbed } from 'react-twitter-embed';
import MainLoading from "./components/MainLoading";
import { Button, Position, Toast, Toaster, Classes, Slider, Drawer, Divider ,Alert, Intent} from "@blueprintjs/core";

const Auth = lazy(() => import("./components/Auth"));
const Panel = lazy(() => import("./components/Panel"));


var key = require('keyboard-shortcut');

class App extends React.Component {
  _mounted = false
  constructor(props){
    super(props);

    this.state = {
      screen:0,
      toasts:[],
      admin:false,
      auth:false,
      loaded:false,
      carousel:[],
      marcas:{},
      laEmpresa:{},
      catalogo:{},
      catalogDisplay:"",
      productDestacado:{},
      noticias:{},

      input:{
        nombre:{
          value:"",
                errorMessage:"",
                pattern:/.*/,
                minLength:0 ,
                maxLength:40,
                valid:false
        },
        correo:{
          value:"",
                errorMessage:"",
                pattern:/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                minLength:0 ,
                maxLength:500,
                valid:false
        },
        asunto:{
          value:"",
                errorMessage:"",
                pattern:/.*/,
                minLength:0 ,
                maxLength:150,
                valid:false
        },
        mensaje:{
                 value:"",
                errorMessage:"",
                pattern:/.*/,
                minLength:0 ,
                maxLength:1500,
                valid:false
        }
      }
    }

    this.toaster = {};
        this.refHandlers = {
            toaster:(ref) => {this.toaster = ref},
        }
  }

  sendMessage = () => {
    let flag = 0;

    Object.keys(this.state.input).forEach(key => {
      if(this.state.input[key].valid === false){
        flag = 1;
      }
    })
    
    if(flag === 0){
      firebase.firestore().collection("inbox").add({
        send:firebase.firestore.Timestamp.now(),
        name:this.state.input.nombre.value,
        email:this.state.input.correo.value,
        issue:this.state.input.asunto.value,
        message:this.state.input.mensaje.value,
        state:false
      })
      .then(() => {
        this.addToast("Gracias por contactarnos!")

        if(this._mounted){
          this.setState(state => {
            let base = state.input;

            Object.keys(base).forEach(key => {
              base[key].value = ""
              base[key].valid = false;
              base[key].errorMessage = ""
            })

            return {
              input:base
            }
          })
        }
      })
      .catch(e => {
        console.log(e);
        this.addToast("Algo salió mal al enviar el mensaje")
      })
    }else {
      this.addToast("Algunos campos son inválidos")
    }
  }

  handleChange = (prop, value) => {
    let flag = 0;
    let message = ""

    let property = Object.assign({}, this.state.input[prop]);

    if(value.length < property.minLength){
        flag = 1;
        message = `${prop} debe tener un mínimo de ${property.minLength} caracteres`
    }

    if(value.length > property.maxLength){
        flag = 1;
        message = `${prop} debe tener un máximo de ${property.maxLength} caracteres` 
    }

    if(property.pattern.test(value) === false){
        flag = 1;
        message = `${prop} inválido`
    }

    if(flag === 0){
        property.value = value;
        property.valid = true;
        property.errorMessage = ""

        if(this._mounted){
            this.setState(state =>{
              let base = state.input;
              base[prop] = property
              return {
                input:base
              }
            })
        }
    }else {

        property.value = value;
        property.valid = false;
        property.errorMessage = message

        if(this._mounted){
          this.setState(state =>{
            let base = state.input;
            base[prop] = property
            return {
              input:base
            }
          })
        }
    }
}

  addToast = (message) => {
    this.toaster.show({ message: message});
}

  componentWillUnmount(){
    this._mounted = false;
  }

  setScreen = (i) => {
    if(this._mounted){
      this.setState({
        screen:i
      })
    }
  }

  getData = () => {
    firebase.firestore().collection("administrador").get()
    .then(snap => {
      let carousel = "";
      let marcas =""
      let laEmpresa ="";
      let catalogo = "";
      let catalogDisplay = "";
      let productDestacado = "";
      let noticias =""
      snap.forEach(doc => {
        if(doc.id === "inicio"){
      carousel = doc.data().carrusel
        }

        if(doc.id === "marcas"){
          marcas = doc.data()
        }

        if(doc.id === "laEmpresa"){
          laEmpresa = doc.data()
        }

        if(doc.id === "catalogo"){
          catalogo = doc.data()
          catalogDisplay = doc.data().catalogo1.presentation.url
        }

        if(doc.id === "productoDestacado"){
          productDestacado = doc.data()
        }

        if(doc.id === "noticias"){
          noticias = doc.data()
        }

      })


      if(this._mounted){
        this.setState({
          carousel:carousel,
          loaded:true,
          marcas:marcas,
          laEmpresa:laEmpresa,
          catalogo:catalogo,
          catalogDisplay:catalogDisplay,
          productDestacado:productDestacado,
          noticias
        })
      }
    })
    .catch(e => {
      console.log(e);

      this.addToast("Algo salió mal")
    })
  }

  componentDidMount(){
    this._mounted = true;

    this.getData();

    firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        if(this._mounted){
          this.setState({
            auth:true,
            screen:2
          })
        }
      } else {
        if(this._mounted){
          this.setState({
            auth:false,
            screen:1
          })
        }
      }
    });

    key('ctrl z x', (e) => {
      if(this._mounted){
        this.setState(state => ({
          admin:!state.admin
        }))
        
      }
    });

    $(window).scroll(() =>{
      var scroll = $(window).scrollTop();
      
      if(scroll < 300){
      
          $('.navbar').css('background', 'transparent');
      } else{
        
          $('.navbar').css('background', 'rgba(40, 50, 134, 0.9)');
      }
  });

  $(document).ready(function(){
    $('a[href^="#"]').on('click',function (e) {
        e.preventDefault();
        var target = this.hash;
        target = $(target);
        $('html, body').stop().animate({
            'scrollTop':  target.offset().top - 90 //no need of parseInt here
        }, 900, 'swing', function () {
            window.location.hash = target;
        });
    });
});
  }

 

  render(){
    return (
      <>
      <Toaster className={Classes.OVERLAY} position={Position.TOP} ref={this.refHandlers.toaster}>
                    {/* "Toasted!" will appear here after clicking button. */}
                    {this.state.toasts.map(toast => <Toast action={{onClick:() => {}, text:"Resend"}} {...toast} />)}
                </Toaster>
      <Suspense fallback={<MainLoading/>}>
      {this.state.admin === false && this.state.loaded === true?<div>
          <nav className="navbar navbar-expand-md fixed-top ">
           
             <a className="navbar-brand" href="#"><img src={logo} width="250px" /></a>
                <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#collapsibleNavbar">
                 <span className="navbar-toggler-icon"><i className="material-icons align-middle" style={{color:"white", fontSize:"24px"}}>menu</i></span>
                </button>
           <div className="collapse navbar-collapse" id="collapsibleNavbar">
             <ul className="navbar-nav mx-auto">
               <li className="nav-item p-3">
                 <a className="nav-link nav-link-1" href="#inicio">INICIO</a>
               </li>
               <li className="nav-item p-3">
                 <a className="nav-link nav-link-1" href="#laEmpresa">LA EMPRESA</a>
               </li>
               <li className="nav-item p-3">
                 <a className="nav-link nav-link-1" href="#productos">PRODUCTOS</a>
               </li>   
               <li className="nav-item p-3">
                 <a className="nav-link nav-link-1" href="#contactenos">CONTÁCTENOS</a>
               </li>   
               <li className="nav-item p-3">
               <a href="https://www.facebook.com/consinmed/" target="_blank" className="nav-link nav-link-1 fa fa-facebook"></a>
               </li>
               <li className="nav-item p-3">
               <a href="https://twitter.com/consinmed" target="_blank" className="nav-link nav-link-1 fa fa-twitter"></a>
               </li>
               <li className="nav-item p-3">
               <a href="https://www.instagram.com/consinmed" className="nav-link nav-link-1 fa fa-instagram"></a>
               </li>
             </ul>
           </div>  
         </nav>

         <div id="inicio" className="carousel slide carousel-special-props" data-ride="carousel">
           <div className="circle-center">
             <div className="circle-content">
               <div className="circle-blue">
                 <div className="circle-content-anim">
                   <div className="circle-anim">

                   </div>
                 </div>
               </div>
             </div>
           </div>
         <div className="carousel-mask">
            </div>
            <div className="carousel-backdrop">

            </div>

  <ul className="carousel-indicators">
  </ul>

  <div className="carousel-inner">
    {this.state.carousel.map((e,i) => {
      return (
        <div className={`carousel-item ${i ===0?"active":""}`} key={i}>
          <img src={e.url} width="100%"/>
        </div>
      )
    })}
 
  </div>


  <a className="carousel-control-prev" href="#inicio" data-slide="prev">
    <span className="carousel-control-prev-icon"></span>
  </a>
  <a className="carousel-control-next" href="#inicio" data-slide="next">
    <span className="carousel-control-next-icon"></span>
  </a>

</div>

<div className="container mt-5">
  <div className="flex-center">
    <img src={Logo_blue} width="300px"/>
  </div>
  <div className="row mt-4">
    <div className="col-sm-4">
      <div className="flex-center">
        <img src={this.state.marcas.marca1.image.url} width="200px" />
      </div>
      <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
      <p className="text-center special-text-1">
      {this.state.marcas.marca1.text}
      </p>
    </div>
    <div className="col-sm-4">
      <div className="flex-center">
        <img src={this.state.marcas.marca2.image.url} width="200px" />
      </div>
      <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
      <p className="text-center special-text-1">
      {this.state.marcas.marca2.text}
      </p>
    </div>
    <div className="col-sm-4">
      <div className="flex-center">
        <img src={this.state.marcas.marca3.image.url} width="200px" />
      </div>
      <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
      <p className="text-center special-text-1">
      {this.state.marcas.marca3.text}
      </p>
    </div>
  </div>
  <div className="row px-5">
  <div className="col-sm-6">
      <div className="flex-center">
        <img src={this.state.marcas.marca4.image.url} width="200px" />
      </div>
      <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
      <p className="text-center special-text-1">
      {this.state.marcas.marca4.text}
      </p>
    </div>

    <div className="col-sm-6">
      <div className="flex-center">
        <img src={this.state.marcas.marca5.image.url} width="200px" />
      </div>
      <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
      <p className="text-center special-text-1">
      {this.state.marcas.marca5.text}
      </p>
    </div>
  </div>
</div>

<div className="container-fluid mt-5" id="laEmpresa">
  <div className="row">
    <div className="col-sm-5 min-height " style={{
      backgroundImage:`url(${this.state.laEmpresa.image1.url})`,
      backgroundSize:"cover",
      backgroundPosition:"center",
      backgroundRepeat:"no-repeat"
    }}>

    </div>

    <div className="col-sm-7 padding-1" style={{borderTop:"1px solid lightgray"}}>
  <h2 className="header-1 px-3">{this.state.laEmpresa.title1}</h2>
      <p className="mt-5">{this.state.laEmpresa.text1}</p>
      <p className="mt-5">
      {this.state.laEmpresa.text2}
      </p>
    </div>
  </div>
  <div className="row">
    <div className="col-sm-6 padding-1 bg-1">
    <h2 className="header-2 px-3">{this.state.laEmpresa.title2}</h2>
    <h5 className="sub-header text-right mt-5">{this.state.laEmpresa.subTitle1}</h5>
      <p className="text-right">{this.state.laEmpresa.subText1}</p>

        <h5 className="sub-header text-right mt-4">{this.state.laEmpresa.subTitle2}</h5>
      <p className="text-right">
      {this.state.laEmpresa.subText2}
      </p>

  <h5 className="sub-header text-right mt-4">{this.state.laEmpresa.subTitle3}</h5>
      <p className="text-right">
        {this.state.laEmpresa.subText3}
      </p>
    </div>
    <div className="col-sm-6  min-height" style={{
      backgroundImage:`url(${this.state.laEmpresa.image2.url})`,
      backgroundSize:"cover",
      backgroundPosition:"center",
      backgroundRepeat:"no-repeat"
    }}>

    </div>
  </div>
</div>

<div className="container padding-1" id="productos">
  <h2 className="text-center header-3">CATÁLOGO DE PRODUCTOS</h2>
  <p className="text-center mt-3 mb-1">Te presentamos nuestras líneas de herramientas para la construcción, tapicería, y sector agrícola</p>
  <div className="flex-center">
    <div className="m-2">
    <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
    </div>
    <div className="m-2 flex-center-2">
      <div className="orange-circle"></div>
    </div>
    <div className="m-2"> 
    <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
    </div>
  </div>
  <div className="flex-center">
    <div className="floating-elements mt-5">
      <div className="float padding-2 catalog-products shadow">
        <div style={{cursor:"pointer"}} onClick={e => {
          if(this._mounted){
            this.setState({
              catalogDisplay:this.state.catalogo.catalogo1.presentation.url
            })
          }

          window.open(this.state.catalogo.catalogo1.file.url, '_blank')
        }}>
        <div className="flex-center">
          <img src={this.state.catalogo.catalogo1.image.url} width="200px" />
        </div>
        <h5 className="header-3 text-center">{this.state.catalogo.catalogo1.name}</h5>
        <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
        </div>

        <div style={{cursor:"pointer"}} onClick={e => {
          if(this._mounted){
            this.setState({
              catalogDisplay:this.state.catalogo.catalogo2.presentation.url
            })
          }

          window.open(this.state.catalogo.catalogo2.file.url, '_blank')
        }}>
        <div className="flex-center">
          <img src={this.state.catalogo.catalogo2.image.url} width="200px"/>
        </div>
        <h5 className="header-3 text-center">{this.state.catalogo.catalogo2.name}</h5>
        <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
        </div>

        <div style={{cursor:"pointer"}} onClick={e => {
          if(this._mounted){
            this.setState({
              catalogDisplay:this.state.catalogo.catalogo3.presentation.url
            })
          }

          window.open(this.state.catalogo.catalogo3.file.url, '_blank')
        }}>
        <div className="flex-center">
          <img src={this.state.catalogo.catalogo3.image.url} width="200px"/>
        </div>
        <h5 className="header-3 text-center">{this.state.catalogo.catalogo3.name}</h5>
        <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
        </div>

        <div style={{cursor:"pointer"}} onClick={e => {
          if(this._mounted){
            this.setState({
              catalogDisplay:this.state.catalogo.catalogo4.presentation.url
            })
          }

          window.open(this.state.catalogo.catalogo4.file.url, '_blank')
        }}>
        <div className="flex-center">
          <img src={this.state.catalogo.catalogo4.image.url} width="200px" />
        </div>
        <h5 className="header-3 text-center">{this.state.catalogo.catalogo4.name}</h5>
        <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
        </div>
      </div>
      <div className="float img-products" style={{
        backgroundImage:`url(${this.state.catalogDisplay})`,
        backgroundSize:"cover",
        backgroundRepeat:"no-repeat",
        backgroundPosition:"center"
      }}>

      </div>
    </div>
    </div>
</div>

<div className="container-fluid padding-1 bg-2" style={{marginTop:"5em"}}>
  <h2 className="header-3 text-center" style={{color:"white"}}>PRODUCTO DESTACADO</h2>
  <div className="flex-center">
  <div className="highlight-product-panel padding-2 mt-4">
    <div className="img-highlight-product">
      <img src={this.state.productDestacado.image.url}/>
    </div>
    <div className="writes-width" style={{maxWidth:"70%"}}>
    <h2 className="header-3 special-text-2">{this.state.productDestacado.productName}</h2>

    <p className="color-white mt-4">{this.state.productDestacado.text1}</p>

      <h5 className="header-3 color-white mt-4">{this.state.productDestacado.subTitle1}</h5>
    <p className="color-white">{this.state.productDestacado.subText1}</p>

       <h5 className="header-3 color-white mt-4">{this.state.productDestacado.subTitle2}</h5>
      <p className="color-white">{this.state.productDestacado.subText2}</p>
      </div>
  </div>
  </div>
</div>
<div className="container-fluid padding-1 bg-5">
  <div className="container">
  <div className="row mt-5">
    <div className="col-sm-6 p-4">
      <h3 className="header-3 text-center">TWITTER</h3>
      <div className="flex-center">
      <TwitterTimelineEmbed
      className="mt-4"
  sourceType="profile"
  screenName="consinmed"
  options={{height: 600, width:400}}
/>
</div>
    </div>
    <div className="col-sm-6 p-4">
      <h3 className="header-3 text-center">FACEBOOK</h3>
      <div className="flex-center">
      <iframe className="mt-4" src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fconsinmed%2F&tabs=timeline&width=340&height=500&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId=640676326668622" width="400" height="600" style={{border:"none", overflow:"hidden"}} scrolling="no" frameBorder="0" allow="encrypted-media"></iframe>
      </div>
    </div>
  </div>
  </div>
</div>

<div className="container padding-1">
  <h2 className="header-3 text-center">NOTICIAS</h2>

  {this.state.noticias.list.map((e,i) => {
    return (
      <div className="row mt-5 news shadow" key={i}>
    <div className="col-sm-5 min-height" style={{
      backgroundImage:`url(${e.image.url})`,
      backgroundSize:"contain",
      backgroundRepeat:"no-repeat",
      backgroundPosition:"center"
    }}>

    </div>
    <div className="col-sm-7 padding-2 bg-3 color-white">
      <p>{e.text}</p>
    </div>
  </div>
    )
  })}
 

</div>
<div className="container-fluid padding-1 bg-contact contact-section" id="contactenos">
  <div className="container">
  <h2 className="header-3 text-center" style={{color:"rgb(50,50,50)", zIndex:999}}>CONTÁCTENOS</h2>
  <div className="flex-center">
    <div className="m-2">
    <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
    </div>
    <div className="m-2 flex-center-2">
      <div className="orange-circle"></div>
    </div>
    <div className="m-2"> 
    <hr style={{width:"70px", borderWidth:"1.5px", borderColor:"gray"}}></hr>
    </div>
  </div>


  <div className="row mt-4" style={{zIndex:9}}>
    <div className="col-sm-5 p-3">
      <div className="padding-1 shadow bg-4" style={{height:"100%"}}>
      <h5 className="sub-header text-left">Nuestra Dirección</h5>
      <p className="mt-3">C. E. Caribe, Galpon B1, Urb. Industrial Castillito, San Diego, Edo. Carabobo.</p>

      <h5 className="sub-header text-left mt-4">Teléfonos</h5>
      <p className="mt-3">0241-871.46.19 - 871.71.54</p>

      <h5 className="sub-header text-left mt-4">Correo Electrónico</h5>
      <p className="mt-3">info@consinmed.com</p>
      </div>
    </div>

    <div className="col-sm-7 p-3">
      <div className="padding-1 shadow bg-4" style={{height:"100%"}}>
        <div className="form-group">
          <label>Nombre</label>
          <input className="form-control form-control-custom" type="text" value={this.state.input.nombre.value} onChange={e => {
            e.persist()
            this.handleChange('nombre',e.target.value)
          }}/>
          <span className="text-danger">{this.state.input.nombre.errorMessage}</span>
        </div>
        <div className="form-group">
          <label>Correo Electrónico</label>
          <input className="form-control form-control-custom" value={this.state.input.correo.value} type="text" onChange={e => {
            e.persist()
            this.handleChange('correo',e.target.value)
          }}/>
          <span className="text-danger">{this.state.input.correo.errorMessage}</span>
        </div>
        <div className="form-group">
          <label>Asunto</label>
          <input className="form-control form-control-custom" value={this.state.input.asunto.value} type="text" onChange={e => {
            e.persist()
            this.handleChange('asunto',e.target.value)
          }}/>

          <span className="text-danger">{this.state.input.asunto.errorMessage}</span>
        </div>

        <div className="form-group">
          <label>Mensaje</label>
          <textarea className="form-control form-control-custom" value={this.state.input.mensaje.value} rows="3" onChange={e => {
            e.persist()
            this.handleChange('mensaje',e.target.value)
          }}></textarea>
          <span className="text-danger">{this.state.input.mensaje.errorMessage}</span>
        </div>
        <div className="form-group mt-2">
          <button className="btn btn-custom" onClick={e => {
            this.sendMessage();
          }}>Enviar Mensaje</button>
        </div>
      </div>
    </div>
  </div>
  </div>
</div>
      </div>:this.state.admin === false?<MainLoading/>:null}
      {this.state.screen === 1 && this.state.auth === false && this.state.admin === true?<Auth setScreen={this.setScreen} addToast={this.addToast}/>:null}
      {((this.state.auth === true) && (this.state.screen ===2) && (this.state.admin === true))?<Panel addToast={this.addToast}/>:null}
      </Suspense>
      </>
    )
}
}

export default App;
