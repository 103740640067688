import firebase from "firebase";
import "firebase/app";
import "firebase/firestore";
import "firebase/storage";
import "firebase/analytics";

const firebaseConfig = {
    apiKey: "AIzaSyBzQ9_7A3OYnoOxpfywg0D3B2WWioekNhM",
    authDomain: "consinmed-1e8f9.firebaseapp.com",
    databaseURL: "https://consinmed-1e8f9.firebaseio.com",
    projectId: "consinmed-1e8f9",
    storageBucket: "consinmed-1e8f9.appspot.com",
    messagingSenderId: "165367380068",
    appId: "1:165367380068:web:8683f54049e9b5da09c771",
    measurementId: "G-6T31DM4HFV"
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
  firebase.analytics();

  export default firebase;